<template lang="pug">
  .exam-item
    slot
    p.exam-item__title {{ exam.text }} {{ exam.licenseType ? `- ${exam.licenseType}` : '' }}
    slot(name="actions")
</template>

<script>
export default {
  props: {
    exam: Object
  }
}
</script>

<style lang="scss" scoped>
.exam-item {
  display: flex;
  align-items: center;

  &__title {
    margin-bottom: 0;
  }

  &__counts {
    flex-grow: 1;
  }
}
</style>
